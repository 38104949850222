import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CompactPostList from '../components/compact-post-list'
import { graphql } from 'gatsby'
import FullProjectList from '../components/full-project-list'
import { Link } from 'gatsby'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const postLimit = 7
  const posts = edges
    .filter(edge => edge.node.fields.blog)
    .filter((i, index) => index < postLimit)

  const projects = edges
    .filter(edge => edge.node.frontmatter.project)
    .filter(edge => edge.node.frontmatter.summary_image != null)
    .filter((i, index) => index < 3)

  return (
    <Layout pageTitle="Mike Moran, software engineer, dad and baldie">
      <SEO title="Home Page" />
      <hr />
      <h2 className="title">Posts</h2>
      <CompactPostList posts={posts} />
      <hr />
      <h2 className="title">Projects</h2>
      <FullProjectList projects={projects} />
      <div className="columns">
        <div className="column has-text-right">
          <Link to="/projects">more...</Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [fields___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 100)
          frontmatter {
            title
            project
            summary_image
          }
          fields {
            path
            date
            blog
          }
        }
      }
    }
  }
`
