import PropTypes from 'prop-types'
import React from 'react'
import PostLink from './post-link'
import { Link } from 'gatsby'

import styles from './compact-post-list.module.scss'

const CompactPostList = ({ posts }) => (
  <>
    <div className="columns">
      <div className="column">
        <div className={styles.posts}>
          <ul>
            <>
              {posts.map(edge => (
                <li key={edge.node.id} className={styles.post}>
                  <PostLink post={edge.node} />
                </li>
              ))}
              <li key="more">
                <Link to="/posts">more...</Link>
              </li>
            </>
          </ul>
        </div>
      </div>
    </div>
  </>
)

CompactPostList.propTypes = {
  posts: PropTypes.any,
}

CompactPostList.defaultProps = {
  posts: [],
}

export default CompactPostList
